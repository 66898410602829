@use "../bootstrap.scss" as bootstrap;
@use "../global/_global-dir" as *;

.content-topnav {
  @include display-flex(flex, row, nowrap);
  background-color: white;
  color: var(--text-color);
  // position: sticky;
  // top: 0;
  z-index: 1050;
  align-items: center;
  justify-content: center;

  .content-topnav-container {
    @include display-flex(flex, row, nowrap);
    flex: 1 1 50%;
    padding: 8px 45px 7px 45px;
    max-width: 1300px;
    justify-content: center;

    @include bootstrap.media-breakpoint-down(sm) {
      padding: 8px 10px 7px 10px;
    }
  }

  .content-topnav-content {
    @include display-flex(flex, row, nowrap);
    margin-bottom: 0px;
    align-items: center;
    justify-content: center;
    flex: 2 1 100%;
  }

  // Title of the page
  .page-info {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    @include bootstrap.media-breakpoint-down(sm) {
      flex-flow: column nowrap;
    }

    h3 {
      font-size: 1.3rem;
      text-transform: uppercase;
      font-weight: $font-weight-semibold;
    }
  }

  .back-button-container {
    display: flex;
    flex: 1 1 25%;
    align-items: center;
    justify-content: flex-start;
  }

  .back-button {
    @include display-flex(flex, row, nowrap);
    text-transform: uppercase;
    padding: 0rem 1rem;
    height: 40px;
    font-size: 0.875rem;

    > span {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      text-align: left;
      line-height: 1rem;
    }

    & i {
      font-size: 1.875rem;
      font-weight: 200;
      width: 1.875rem;
      text-align: center;
      line-height: 1rem;
    }

    &:hover {
      color: var(--primary-color);
    }

    @include bootstrap.media-breakpoint-down(md) {
      .button-text {
        display: none;
      }
    }
  }

  .forward-button-container {
    display: flex;
    flex: 1 1 25%;
    align-items: center;
    justify-content: flex-end;
    max-width: 25%;
  }

  .forward-button {
    @include display-flex(flex, row, nowrap);
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    height: 40px;

    > span {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    & i {
      font-size: 1.875rem;
      font-weight: 200;
      width: 1rem;
      text-align: center;
      line-height: 1rem;
      margin-left: 0.5rem;
    }

    @include bootstrap.media-breakpoint-down(md) {
      padding: 0.4rem 0.7rem;
    }

    @include bootstrap.media-breakpoint-down(sm) {
      i {
        margin-left: 0;
      }

      .button-text {
        display: none;
      }
    }
  }
}
