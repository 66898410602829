@use "../global/_global-dir" as *;

.page404-container {
    display: flex;
    flex-flow: row;
    padding: 0 !important;
    // max-width: 100vw;
    width: inherit;
    position: relative;

    .ornaments-svg {
        display: flex;
        flex-flow: row;
        flex: 1;
        justify-content: space-between;
        position: relative;
        filter: saturate(0);
        opacity: 0.5;

        .ornament-bottom-container {
            display: flex;
            width: 100%;
            min-height: 100%;
            align-items: flex-end;
            justify-content: center;
            position: absolute;
        }
    }

    .page404-content-container {
        display: flex;
        width: 100%;
        min-height: 100%;
        align-items: center;
        top: 20%;
        position: absolute;
        flex-flow: column nowrap;
    }

    .content-container {
        display: flex;
        flex-flow: column;
        max-width: 500px;
        width: 500px;
        transition: width ease 0.3s;

        @media (max-width: 500px) {
            max-width: 400px;
            width: 400px;
        }
    }

    .page404-content-image {
        display: flex;
        justify-content: center;

        svg {
            height: 100px;
            width: auto;
        }
    }

    .page404-content-text {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        margin: 2rem 0;
    }

    h3 {
        font-weight: $font-weight-semibold;
        font-size: 1.3rem;
        line-height: 25px;
        text-align: left;
        line-height: 1.8rem;
    }

    span {
        font-size: 1.05rem;
        line-height: 1.6rem;
    }
}
